import { Component } from '@angular/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  refreshingToken = false;

  constructor(private readonly appSrv: AppService, private readonly router: Router) {
    if(document.location.href.endsWith('/logout') || document.location.href.includes('/code/')) {
      return;
    }
    const urlRedir = decodeURIComponent(window.location.hash.replace('#', ''));
    this.appSrv.setRedirectionURL(urlRedir);
    this.appSrv.refreshingToken.subscribe((refreshing) => {
      this.refreshingToken =  refreshing;
    });
    this.appSrv.validateAndRedirectGET();
  }
}
