import { EventEmitter, Injectable } from '@angular/core';
import { LoginService } from './pages/login/login-form/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private readonly loginSrv: LoginService, private readonly router: Router) { }

  setRedirectionURL(url?: string) {
    if(url) {
      localStorage.setItem('redirection_url', url);
    }
  }

  getRedirectionURL() {
    return localStorage.getItem('redirection_url')!;
  }

  setSession(data: { jwt: string, expiration: number, user_types: {label: string, redirection_url: string}[],}) {
    localStorage.setItem('session', JSON.stringify(data));
  }

  setRefreshedSession(data: { jwt: string, expiration: number }) {
    const session = this.getSession();
    session!.jwt = data.jwt;
    session!.expiration = data.expiration;
    localStorage.setItem('session', JSON.stringify(session));
  }

  getSession(): { jwt: string, expiration: number, user_types: {label: string, redirection_url: string}[]} | null {
    return JSON.parse(localStorage.getItem('session')!);
  }

  // validateAndRedirectPOST() {
  //   const session = this.getSession();
  //   if(!session || session.expiration < Date.now()) {
  //     return false;
  //   }
  //   const redirection = session.user_types.find((userType) => userType.redirection_url == this.getRedirectionURL());
  //   if(!redirection) {
  //     return false;
  //   }
  //   this.makePost();
  //   return true;
  // }

  async validateAndRedirectGET(redirURI?: string) {
    const session = this.getSession();
    if(!session) {
      return false;
    }
    if(session.expiration < Date.now()) {
      return this.refreshToken(session!.jwt);
    }
    // const redirection = session.user_types.find((userType) => userType.redirection_url == this.getRedirectionURL());
    // same as previous but only check the subdomain and domain using URL class:
    const uri = this.getRedirectionURL() || redirURI;
    let redirectionURI = null;
    localStorage.removeItem('redirection_url');
    if(uri) {
      const redirection = session.user_types.find((userType) => (new URL(userType.redirection_url)).origin == (new URL(uri)).origin);
      if(redirection) {
        return this.redirect(uri);
      }
    } else {
      const redirs = session.user_types.filter(u => u.redirection_url && u.redirection_url !== '');
      if(redirs.length > 1) {
        this.router.navigateByUrl('/selection', { state: { user_types: session.user_types } });
        return true;
      } else if(redirs.length == 1) {
        return this.redirect(redirs[0].redirection_url);
      }
    }
    return false;
  }

  redirect(redirectionURI: string) {
    window.location.href = redirectionURI + '#' + btoa(JSON.stringify(this.getSession()));
    return true;
  }

  refreshingToken: EventEmitter<boolean> = new EventEmitter<boolean>();
  refreshToken(oldJWT: string) {
    return new Promise((resp) => {
      this.refreshingToken.emit(true);
      this.loginSrv.refreshToken(oldJWT).subscribe({
        next: (res) => {
          this.refreshingToken.emit(false);
          this.setRefreshedSession(res);
          // const uri = this.getRedirectionURL();
          // window.location.href = uri + '#' + btoa(JSON.stringify(this.getSession()));
          resp(this.validateAndRedirectGET());
        }, error: (err) => {
          resp(false);
          this.refreshingToken.emit(false);
          console.error('Error refreshing token', err);
        }
      })
    });
  }

  makePost() {
    let form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", this.getRedirectionURL());
    const jwtInput = document.createElement("input");
    jwtInput.setAttribute("type", "hidden");
    jwtInput.setAttribute("name", "jwt");
    jwtInput.setAttribute("value", this.getSession()!.jwt);
    const expirationInput = document.createElement("input");
    expirationInput.setAttribute("type", "hidden");
    expirationInput.setAttribute("name", "expiration");
    expirationInput.setAttribute("value", this.getSession()!.expiration.toString());
    form.appendChild(jwtInput);
    form.appendChild(expirationInput);
    document.body.appendChild(form);
    form.submit();
  }
}
